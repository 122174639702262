import { AblyContextProvider } from '@/hooks/AblyContext'
import { PageServerSidePropsParams } from '@/utils/withServerSideProps'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { NextPage } from 'next'
import { ReactElement } from 'react'

export function getPoolPageLayout(page: ReactElement, pageProps: PageServerSidePropsParams) {
    return <LocalizationProvider dateAdapter={AdapterDateFns}>{page}</LocalizationProvider>
}

export function getGlobalPoolPageLayout(page: ReactElement, pageProps: PageServerSidePropsParams) {
    return (
        <AblyContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>{page}</LocalizationProvider>
        </AblyContextProvider>
    )
}

export function getPoolTournamentPageLayout(page: ReactElement, pageProps: PageServerSidePropsParams) {
    return <LocalizationProvider dateAdapter={AdapterDateFns}>{page}</LocalizationProvider>
}

export type NextPageWithLayout<P = Record<string, never>, IP = P> = NextPage<P, IP> & {
    getPageLayout?: (
        page: ReactElement,
        pageProps: PageServerSidePropsParams
    ) => ReactElement<any, any> | null
}
