import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { ChatClient, ChatClientProvider, LogLevel, Message } from '@ably/chat'
import * as Ably from 'ably'
import Push from 'ably/push'
import { AblyProvider } from 'ably/react'
import { useUserSession } from './useUserSession'
import { ApiError, apiHelper } from '@/apiHelper'

type AblyContextType = {
    showChat: boolean
    showNewMessageBadge: boolean
    setShowChat: Dispatch<SetStateAction<boolean>>
    setShowNewMessageBadge: Dispatch<SetStateAction<boolean>>
    messages: Message[]
    setMessages: Dispatch<SetStateAction<Message[]>>
}

const AblyContext = createContext<AblyContextType>({} as AblyContextType)

type AblyContextProviderProps = object

export const AblyContextProvider: FC<PropsWithChildren<AblyContextProviderProps>> = ({ children }) => {
    const [session, , update] = useUserSession()
    const [showChat, setShowChat] = useState(false)
    const [showNewMessageBadge, setShowNewMessageBadge] = useState(false)
    const [messages, setMessages] = useState<Message[]>([])

    useEffect(() => {
        if (showChat) {
            setShowNewMessageBadge(false)
        }
    }, [showChat])

    const authCallback = useCallback(
        async (data, callback) => {
            try {
                // console.log(
                //     `using token ending in ${session?.accessToken.substring(
                //         session?.accessToken.length - 10
                //     )}`
                // )
                const tokenRequest = await apiHelper.get(`/api/users/realtime/token`, session?.accessToken)

                callback(null, tokenRequest)
            } catch (err) {
                if (err instanceof ApiError && err.status === 401) {
                    // console.log('refreshing')
                    update()
                    authCallback(data, callback)
                } else {
                    callback('error' + err, null)
                }
            }
        },
        [session?.accessToken, update]
    )

    const realtimeClient = useMemo(
        () =>
            new Ably.Realtime({
                pushServiceWorkerUrl: '/service-worker.js',
                plugins: { Push },
                logLevel: 0,
                logHandler: (msg) => {
                    console.log(msg)
                },
                authCallback,
                closeOnUnload: true,
                autoConnect: typeof window !== 'undefined',
            }),
        []
    )

    useEffect(() => {
        const client = realtimeClient
        return () => {
            client.close()
        }
    }, [])

    const chatClient = useMemo(() => {
        return realtimeClient ? new ChatClient(realtimeClient, { logLevel: LogLevel.Warn }) : undefined
    }, [realtimeClient])

    return (
        <AblyContext.Provider
            value={{
                setShowChat,
                setShowNewMessageBadge,
                showChat,
                showNewMessageBadge,
                messages,
                setMessages,
            }}
        >
            <AblyProvider client={realtimeClient}>
                <ChatClientProvider client={chatClient}>{children}</ChatClientProvider>
            </AblyProvider>
        </AblyContext.Provider>
    )
}

export const useAblyContext = () => {
    return useContext(AblyContext)
}
